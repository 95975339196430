define([
    "services/requests",
    "helpers/urlHelper",
    "helpers/apiHelper",
    "services/documentQueueService"
], function (requests, urlHelper, apiHelper, documentQueueService) {
    "use strict";

    var exports = {
        getSectionsByDispatchProjectCode: function(dispatchProjectCode) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/documents/sections");
        },

        getSectionsByReceiptId: function (receiptId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/receipt-of-goods/" + receiptId + "/documents/sections");
        },

        getDocumentsByReceiptId: function (receiptId, sectionId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/receipt-of-goods/" + receiptId + "/documents/sections/" + sectionId);
        },

        getSectionsByProjectTransferId: function (transferId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/inventory-project-transfer/" + transferId + "/documents/sections");
        },

        getDocumentsByProjectTransferId: function (transferId, sectionId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/inventory-project-transfer/" + transferId + "/documents/sections/" + sectionId);
        },

        getSectionsBySiteTransferId: function (transferId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/inventory-site-transfer/" + transferId + "/documents/sections");
        },

        getDocumentsBySiteTransferId: function (transferId, sectionId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/inventory-site-transfer/" + transferId + "/documents/sections/" + sectionId);
        },

        getDocumentsByDispatchProjectCode: function (dispatchProjectCode, sectionId) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/documents/sections/" + sectionId);
        },

        getSectionsByDispatchProjectDate: function(dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/" + dispatchDate + "/documents/sections");
        },

        getDocumentsByDispatchProjectDate: function(dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/" + dispatchDate + "/documents/sections/1");
        },

        getSectionsByDispatchId: function(serviceType, dispatchId) {
            return requests.getJson(apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/documents/sections");
        },

        getDispatchDocumentsBySectionId: function(serviceType, dispatchId, sectionId) {
            return requests.getJson(apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/documents/sections/" + sectionId);
        },

        getSectionsByWorkOrderId: function(workOrderId) {
            return requests.getJson(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/documents/sections");
        },

        getDocumentsByWorkOrderId: function(workOrderId, sectionId) {
            return requests.getJson(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/documents/sections/" + sectionId);
        },

        getCertificatesSectionsByDispatchId: function (serviceType, dispatchId) {
            return requests.getJson(apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/certificates/documents/sections");
        },

        getCertificatesDispatchDocumentsBySectionId: function (serviceType, dispatchId, sectionId) {
            return requests.getJson(apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/certificates/documents/sections/" + sectionId);
        },

        getCertificatesSectionsByDispatchProjectDispatchId: function (dispatchId, includeInactive) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl() + "/" + dispatchId + "/certificates/documents/sections?includeInactive="+includeInactive);
        },

        getCertificatesDispatchDocumentsByDispatchProjectDispatchIdSectionId: function (dispatchId, includeInactive, sectionId) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl() + "/" + dispatchId + "/certificates/documents/sections/" + sectionId + "?includeInactive="+includeInactive);
        },

        getEquipmentSectionsByEquipmentCode: function (serviceType, dispatchId, equipmentCode, equipmentId) {
            return requests.getJson(apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment/" + equipmentCode + "/documents/sections" + "?equipmentId=" + equipmentId);
        },

        getEquipmentDocumentsBySectionId: function (serviceType, dispatchId, equipmentCode, equipmentId, sectionId) {
            return requests.getJson(apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment/" + equipmentCode + "/documents/sections/" + sectionId + "?equipmentId=" + equipmentId);
        },

        saveMultipleProjectPictures: function (dispatchProjectCode, dispatchDate, pictureModelList) {
            return documentQueueService.addMultiple('project', dispatchProjectCode, apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/" + dispatchDate + "/documents", pictureModelList)
        },

        saveDispatchPicture: function (serviceType, dispatchId, entityId, pictureModel) {
            return documentQueueService.addMultiple((serviceType === "W" ? "workorder" : "serviceCall"), entityId, apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/documents", pictureModel);
        },

        saveWorkOrderPicture: function (workOrderId, templateCode, pictureModel) {
            var url = apiHelper.appendQueryStringParameters(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/documents", { "templateCode": templateCode });
            return documentQueueService.add('workorder', workOrderId,url, pictureModel);
        },

        saveRecommendationPicture: function (recommendationId, entityType, entityId, dispatchTemplateId, pictureModel) {
            var url = apiHelper.getBaseUrl() + "/recommendations/" + recommendationId + "/documents";
            if (dispatchTemplateId && dispatchTemplateId !== "-1") {
                url += "?dispatchTemplateId=" + dispatchTemplateId;
            }

            return documentQueueService.add(entityType, entityId, url, pictureModel);
        },

        saveRecommendationPictureForDispatch: function (dispatchId, recommendationId, entityType, entityId, pictureModel) {
            return documentQueueService.add(entityType, entityId, apiHelper.getBaseUrl() + "/dispatch/"+dispatchId+"/recommendations/" + recommendationId + "/documents", pictureModel);
        },

        saveEmployeePicture: function (employeeId, pictureModel) {
            return requests.post(apiHelper.getBaseUrl() + "/employees/" + employeeId + "/documents", pictureModel[0]);
        },

        saveEquipmentPicture: function (serviceType, dispatchId, entityId, equipmentCode, equipmentId, pictureModel) {
            if (serviceType === "W")
            {
                return documentQueueService.add( "workorder", entityId, apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment/" + equipmentCode + "/documents", pictureModel);
            }
            return documentQueueService.add("serviceCall", entityId, apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment/" + equipmentCode + "/documents" + "?equipmentId=" + equipmentId, pictureModel);
        },

        saveReceiptPicture: function (receiptId, dispatchTemplateId, pictureModel) {
            return documentQueueService.addMultiple(null, receiptId, apiHelper.getBaseUrl() + "/receipt-of-goods/" + receiptId + "/documents/?dispatchTemplateId=" + dispatchTemplateId, pictureModel);
        },

        saveTransferInventoryProjectPicture: function (transferId, dispatchTemplateId, pictureModel) {
            return documentQueueService.addMultiple(null, transferId, apiHelper.getBaseUrl() + "/inventory-project-transfer/" + transferId + "/documents/?dispatchTemplateId=" + dispatchTemplateId, pictureModel);
        },

        saveTransferInventorySitePicture: function (transferId, dispatchTemplateId, pictureModel) {
            return documentQueueService.addMultiple(null, transferId, apiHelper.getBaseUrl() + "/inventory-site-transfer/" + transferId + "/documents/?dispatchTemplateId=" + dispatchTemplateId, pictureModel);
        },

        saveInspectionPicture: function (inspectionId, pictureModel) {
            return documentQueueService.addMultiple(null, inspectionId, apiHelper.getBaseUrl() + "/inspections/" + inspectionId + "/documents", pictureModel);
        },

        saveMaterialRequisitionPicture: function (requisitionId, dispatchTemplateId, pictureModel) {
            var url = apiHelper.getBaseUrl() + "/materialrequisitions/" + requisitionId + "/documents";
            if (dispatchTemplateId && dispatchTemplateId !== "-1") {
                url += "?dispatchTemplateId=" + dispatchTemplateId;
            }

            return documentQueueService.addMultiple(null, requisitionId, url, pictureModel);
        },

        checkDocumentExist: function (documentId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/documents/" + documentId + "/checkexists");
        },

        getDocumentsByPurchaseOrderId: function (purchaseOrderId, sectionId) {
            const url = `${apiHelper.getBaseUrl() }/purchaseorders/${purchaseOrderId}/documents/sections/${sectionId}`;
            return requests.getJson(url);
        },
        getSectionsByPurchaseOrderId: function (purchaseOrderId) {
            const url = `${apiHelper.getBaseUrl() }/purchaseorders/${purchaseOrderId}/documents/sections`;
            return requests.getJson(url);
        },
        addDocumentByPurchaseOrderId: function(purchaseOrderId, dispatchTemplateId, documentModel) {
            const url = `${ apiHelper.getBaseUrl() }/purchaseorders/${purchaseOrderId}/documents?dispatchTemplateId=${dispatchTemplateId}`;
            return documentQueueService.addMultiple(null, purchaseOrderId, url, documentModel);
        },

        getDocumentsByQuotationId: function(quotationId, sectionId) {
            const url = `${apiHelper.getBaseUrl() }/service-call-quotations/${quotationId}/documents/sections/${sectionId}`;
            return requests.getJson(url);
        },

        getSectionsByQuotationId: function(quotationId) {
            const url = `${apiHelper.getBaseUrl() }/service-call-quotations/${quotationId}/documents/sections`;
            return requests.getJson(url);
        },

        getSectionsByInspectionId: function(inspectionId) {
            return requests.getJson(apiHelper.getInspectionBaseUrl(inspectionId) + "/documents/sections");
        },

        getDocumentsByInspectionId: function(inspectionId, sectionId) {
            return requests.getJson(apiHelper.getInspectionBaseUrl(inspectionId) + "/documents/sections/" + sectionId);
        },

        addDocumentByInspectionId: function(inspectionId, documentModel) {
            const url = `${ apiHelper.getInspectionBaseUrl(inspectionId) }/documents`;
            return documentQueueService.addMultiple(null, inspectionId, url, documentModel);
        },

        deleteDocument: function(documentId) {
            return requests.remove(apiHelper.getBaseUrl() + "/documents/" + documentId);
        },

        getThumbnails: function(documentIds) {
            return requests.post(apiHelper.getBaseUrl() + "/documents/thumbnails", documentIds)
        },

        getSectionsByPhysicalInventoryId: function (physicalInventoryId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/physical-inventory/" + physicalInventoryId + "/documents/sections");
        },

        getDocumentsByPhysicalInventoryId: function (physicalInventoryId, sectionId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/physical-inventory/" + physicalInventoryId + "/documents/sections/" + sectionId);
        },

        savePhysicalInventoryPicture: function (physicalInventoryId, dispatchTemplateId, pictureModel) {
            return documentQueueService.addMultiple(null, physicalInventoryId, apiHelper.getBaseUrl() + "/physical-inventory/" + physicalInventoryId + "/documents/?dispatchTemplateId=" + dispatchTemplateId, pictureModel);
        }
    };

    return exports;
});
