import val from "core/val";
import apiHelper from "helpers/apiHelper";
import routerHelper from "helpers/routerHelper";
import { PhysicalInventoryService } from "services/physical-inventory-service";
import { I18N } from "aurelia-i18n";
import { autoinject, observable } from "aurelia-framework";
import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { NotificationHelper } from "helpers/notification-helper";
import { PhysicalInventoryStatus } from "api/enums/physical-inventory-status";
import { PhysicalInventoryModel } from "api/models/company/physical-inventory-model";

@autoinject
export class AddItem {
    public val: any = val;
    public Id: number = 0;
    public physInv: PhysicalInventoryModel | null = null;
    @observable public selectedItem: any;
    public catalogUrl: string = apiHelper.getBaseUrl() + "/catalog/inventory-transfer";

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly service: PhysicalInventoryService) {
    }

    public async activate(params: any): Promise<void> {
        this.Id = params.id;
        this.physInv = await this.service.Get(this.Id);
    }

    public async save(): Promise<void> {
        if (this.physInv!.Status !== PhysicalInventoryStatus.Entered) {
            return;
        }

        await this.service.save(this.physInv!);

        routerHelper.navigateBack();
    }

    public async selectedItemChanged(newValue: any): Promise<void> {

        if (!newValue || !newValue.data) { return; }

        const catalogItem: CatalogItemModel = newValue.data;

        if (catalogItem) {
            const items = await this.service.GetNewPhysicalInventoryDetail(this.Id, catalogItem.Code);

            if (items!.length === 0) {
                this.notificationHelper.showWarning(this.i18n.tr("msg_PhysicalInventoryNoItemFound"), "");
                return;
            } else {
                this.notificationHelper.showSuccess(this.i18n.tr("msg_PhysicalInventoryItemFound").replace("{0}", items!.length.toString()), "");
            }

            this.physInv!.Items! = this.physInv!.Items!.concat(items);
            await this.save();
        }
    }
}
