import { TemplateService } from "services/template-service";
import moment from "moment";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { computedFrom } from "aurelia-binding";

import { DispatchProjectItemsCountModel } from "api/models/company/project/dispatch-project-items-count-model";
import { ProjectDispatchDateModel } from "api/models/company/project/dispatch/project-dispatch-date-model";

import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";

import defaultService from "services/defaultService";
import dispatchService from "services/dispatchService";
import projectService from "services/projectService";
import UserAccessService from "services/user-access-service";
import { DispatchProjectService } from "services/dispatch-project-service";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import settingHelper from "helpers/settingHelper";
import notificationHelper from "helpers/notificationHelper";

@autoinject
export class ProjectDetail {
    public dateHelper: typeof dateHelper = dateHelper;
    public enumHelper: typeof enumHelper = enumHelper;
    public routerHelper: typeof routerHelper = routerHelper;

    public sectionOnGoing: string = "ongoing";
    public sectionClose: string = "close";

    public sectionShown: string = "";
    public dispatchProjectCode: string = "";
    public list: any[] = [];
    public ongoingList: any[] = []; //was observable
    public closeList: any[] = []; //was observable
    public counts!: DispatchProjectItemsCountModel;
    public isProjectResponsible: boolean = false;
    public noDataText: string = "";
    public moreEntriesLoading: boolean = false;
    public enableMoreEntries: boolean = true;
    public activeProject: boolean = false;

    @computedFrom("ON_GOING_HAS_ONLY_ONE_PAGE", "sectionShown")
    public  get displayMoreEntries(): boolean {
        return !this.ON_GOING_HAS_ONLY_ONE_PAGE && this.sectionShown === this.sectionOnGoing;
    }

    public page: any = { close: 1, ongoing: 1 };
    public maxDate: Date = moment().toDate();
    // public maxDateSubscribe = "";
    public loadingStatus: boolean = false;
    public killScroll: boolean = true;
    public isScrollMessageShown: boolean = false;

    public lblShowMoreEntries: string = "";
    public scrollMessageText: string = "";

    public projectCode: string = "";

    public ON_GOING_HAS_ONLY_ONE_PAGE: boolean = false;
    public statusesToIncludeInCompletedTab: any[] = [ enumHelper.DailyEntryStatus.COMPLETE, enumHelper.DailyEntryStatus.TRANSFERRED, enumHelper.DailyEntryStatus.APPROVED,
    enumHelper.DailyEntryStatus.ACCEPTED, enumHelper.DailyEntryStatus.INPROGRESSGP];
    public statusesToExcludeInCurrentTab: any[] = [enumHelper.DailyEntryStatus.COMPLETE, enumHelper.DailyEntryStatus.TRANSFERRED, enumHelper.DailyEntryStatus.APPROVED,
    enumHelper.DailyEntryStatus.ACCEPTED, enumHelper.DailyEntryStatus.RETURNED, enumHelper.DailyEntryStatus.INPROGRESSGP];

    constructor(private readonly i18n: I18N, private readonly userAccessService: UserAccessService, private readonly dispatchProjectService: DispatchProjectService, private readonly templateService: TemplateService) {
    }

    public async activate(params: any): Promise<any> {
        const queryParams = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (queryParams && queryParams.isProjectResponsible === "true") || false;
        this.dispatchProjectCode = params.id;

        this.counts = await projectService.getItemsCount(params.id);
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.id;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    public async bind(): Promise<any> {
        this.lblShowMoreEntries = this.i18n.tr("ShowMoreEntries");
        this.scrollMessageText = this.i18n.tr("ScrollForMore");

        this.ongoingList = [];
        this.closeList = [];
        this.sectionShown = "";

        if (!this.sectionShown) {
            this.sectionShown = "ongoing";
            await this.loadData(this.dispatchProjectCode);
        } else {
            await this.show(this.sectionShown);
        }
    }

    public getDailyEntryUrl(startTime: Date): string {
        const url = routerHelper.getRelativeUrl("dailyEntry", dateHelper.dateToNavigationParameter(startTime)) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible });
        return url;
    }

    public genUrl(rel: string): string {
        return routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({
            isProjectResponsible: this.isProjectResponsible,
            dispatchProjectCode: this.dispatchProjectCode,
            projectCode: this.projectCode,
            isClosingDayMode: false,
            readonly: false
        });
    }

    public showMoreEntries(isCompletedTab: boolean): any {
        this.lblShowMoreEntries = this.i18n.tr("LoadingMoreEntries");
        this.moreEntriesLoading = true;
        this.loadingStatus = true;
        this.page[this.sectionShown] += 1;

        const statusesToInclude = isCompletedTab ? this.statusesToIncludeInCompletedTab : null;
        const statusesToExclude = !isCompletedTab ? this.statusesToExcludeInCurrentTab : null;

        // On tri par desc lorsqu'on veut les complétés
        const sortByAsc = !isCompletedTab;

        // Si on est dans l'onglet d'entrées complétées, on ne veut pas les retours d'entrées
        const includeReturnedEntries = !isCompletedTab;

        dispatchService.getListByDispatchProjectCode(this.dispatchProjectCode, statusesToInclude, statusesToExclude, sortByAsc,
            includeReturnedEntries, this.page[this.sectionShown], isCompletedTab ? dateHelper.dateToNavigationParameter(this.maxDate) : null)
            .then((data: ProjectDispatchDateModel[]) => {
                let actualLs = isCompletedTab ? this.closeList : this.ongoingList;
                actualLs = actualLs.concat(data);

                this.lblShowMoreEntries = this.i18n.tr("ShowMoreEntries");
                this.moreEntriesLoading = false;
                this.loadingStatus = false;

                const pageSize = defaultService.getPageSize();

                if (isCompletedTab) {
                    this.closeList = actualLs;

                    if (data.length === 0) {
                        this.killScroll = true;
                        this.disposeScroll();
                    } else if (data.length >= pageSize) {
                        this.initScroll();
                        this.killScroll = false;
                    } else {
                        this.killScroll = true;
                        this.disposeScroll();
                    }

                } else {
                    this.ongoingList = actualLs;
                    this.enableMoreEntries = data.length >= pageSize;
                }

                this.list = actualLs;
            }, () => {
                this.list = [];
            });
    }

    public show(section: string): void {
        this.list = [];
        this.sectionShown = section;
        if (section === this.sectionClose) {
            this.loadDispatchesList(this.closeList, true);

            if (!this.killScroll) {
                this.initScroll();
            }
        } else {
            this.loadDispatchesList(this.ongoingList, false);
            this.disposeScroll();
        }
    }

    private async loadDispatches(dispatchProjectCode: string, isCompletedTab: boolean, maxDate?: Date): Promise<void> {
        await this.checkIsProjectActive();
       
        routerHelper.showLoading();

        const statusesToInclude = isCompletedTab ? this.statusesToIncludeInCompletedTab : null;
        const statusesToExclude = !isCompletedTab ? this.statusesToExcludeInCurrentTab : null;

        // On tri par desc lorsqu'on veut les complétés
        const sortByAsc = !isCompletedTab ;

        // Si on est dans l'onglet d'entrées complétées, on ne veut pas les retours d'entrées
        const includeReturnedEntries = !isCompletedTab;
        const pageSize = defaultService.getPageSize();

        await dispatchService.getListByDispatchProjectCode(dispatchProjectCode, statusesToInclude, statusesToExclude, sortByAsc, includeReturnedEntries, 1, maxDate ? dateHelper.formatDateToSend(maxDate) : undefined)
            .then( (data: ProjectDispatchDateModel[]) => {
                const tempList = data;
                if (isCompletedTab) {
                    this.closeList = tempList;
                    if (!tempList.length) {
                        this.noDataText = this.i18n.tr("NoHistoricData");
                        this.disposeScroll();
                    } else {
                        if (tempList.length === 0) {
                            this.killScroll = true;
                            this.disposeScroll();
                        } else if (tempList.length === pageSize) {
                            this.initScroll();
                            this.killScroll = false;
                        } else {
                            this.killScroll = true;
                            this.disposeScroll();
                        }
                    }
                    this.loadingStatus = false;
                } else {
                    this.ongoingList = tempList;
                    if (!tempList.length) {
                        this.noDataText = this.i18n.tr("NoDispatchesPlanned");
                        this.ON_GOING_HAS_ONLY_ONE_PAGE = true;
                    } else {
                        this.ON_GOING_HAS_ONLY_ONE_PAGE = !(tempList.length >= pageSize);
                        this.enableMoreEntries = tempList.length >= pageSize;
                    }
                }

                this.list = tempList;
                routerHelper.hideLoading();
            });
    }

    private async checkIsProjectActive(): Promise<void> {
        const projectModel = (await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode))!;
        this.projectCode = projectModel.Id!;

        this.activeProject = true;
    }

    private initScroll(): void {
        jQuery(document).scroll(this.scrollHandler.bind(this));
        this.showScrollMessage("scroll");
    }
    private disposeScroll(): void {
        jQuery(document).off("scroll");
        this.hideScrollMessage();
    }

    private showScrollMessage(msgId: string): void {
        if (msgId === "loading") {
            this.scrollMessageText = this.i18n.tr("LoadingMoreResults");
        } else {
            this.scrollMessageText = this.i18n.tr("ScrollForMore");
        }

        this.isScrollMessageShown = true;
    }

    private hideScrollMessage(): void {
        this.isScrollMessageShown = false;
    }

    private scrollHandler(this: any): void {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessage("loading");
            this.showMoreEntries(true);
        }
    }

    private async loadData(dispatchProjectCode: string): Promise<any> {
        await this.templateService.getTemplateConfigs(settingHelper.getSelectedDispatchModel());
        await this.loadDispatches(dispatchProjectCode, false);
    }

    private async loadDispatchesList(list: any[], isCompletedTab: boolean): Promise<any> {
        if (list.length) {
            routerHelper.showLoading();
            this.list = list;
            routerHelper.hideLoading();
        } else {
            this.loadDispatches(this.dispatchProjectCode, isCompletedTab, this.maxDate);
        }
    }
}
